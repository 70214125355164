// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-coaching-tsx": () => import("./../../../src/templates/coaching.tsx" /* webpackChunkName: "component---src-templates-coaching-tsx" */),
  "component---src-templates-offerings-tsx": () => import("./../../../src/templates/offerings.tsx" /* webpackChunkName: "component---src-templates-offerings-tsx" */),
  "component---src-templates-testimonial-tsx": () => import("./../../../src/templates/testimonial.tsx" /* webpackChunkName: "component---src-templates-testimonial-tsx" */)
}

